// app.vue
<template>
    <div class="home-app">
        <div class="home-nav">
            <div class="home-nav-content">
                <div class="nav-logo">
                    <img src="./static/logo.svg"> 
                    <div class="name">OJBK - AI视频消除</div>
                </div>
                <div class="btns">
                    <div class="contact-tips">
                        新用户立享5折优惠
                    </div>
                    <div class="contact">
                        <div 
                            class="btn"
                            @mouseenter="onShowCode"
                            @mouseout="onHideCode">
                            获取卡密，无限使用
                        </div>
                        <div 
                            v-if="codeShow"
                            @mouseenter="onShowCode"
                            @mouseout="onHideCode"
                            class="contact-dialog animate__animated animate__zoomInDown">
                            <img src="./static/code.jpg">
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="home-content">
            <div class="banner">
                <div class="banner-text">
                    双AI引擎，<div>满足多场景视频消除需求</div>
                </div>
                <div class="banner-tips">
                    零操作门槛，让创作更加简单
                </div>
                <div class="download-btn-wrapper">
                    <div id="download" class="download">
                    
                        <div 
                            @click="onDownloadVersion"
                            class="download-btn">
                            <img src="./static/windows-fill.svg"/>
                            <span>
                                免费体验，立即下载 
                                <!-- <span style="font-size: 12px; color: #00c1cd;">(解压即用，免安装)</span>  -->
                            </span>
                        </div>
                        <div
                            @click="onPanLink" 
                            class="pan-link">下载速度太慢？前往百度网盘下载</div>
                    </div>
                </div>
            </div>
            <div class="menu-tab">
                <div 
                    class="menu-item"
                    :class="menuType == 'all' ? 'active' : ''"
                    @click="onMenuType('all')">
                    全部功能
                </div>
                <div 
                    class="menu-item"
                    :class="menuType == 'remove' ? 'active' : ''"
                    @click="onMenuType('remove')">
                    消除功能
                </div>
                <div 
                    class="menu-item"
                    :class="menuType == 'srt' ? 'active' : ''"
                    @click="onMenuType('srt')">
                    字幕功能
                </div>
            </div>
            <div class="cate-list-wrapper">
                <div 
                    class="cate-list"
                    :class="[cate.name, 'now-' + cateType]"
                    v-for="cate in cateList"
                    v-if="cate.list.filter(item => item.show).length">
                    <div
                        class="cate-items">
                        <div
                            class="cate-item" 
                            :class="[item.cateType, item.name]"
                            v-if="item.show"
                            v-for="item in cate.list"
                            @click="onChangeActiveCateType(item.name)">
                            <div class="text">
                                <div class="title">
                                    {{item.title}}
                                </div>
                                <div class="des">
                                    {{item.des}}
                                </div>
                            </div>
                            <div 
                                class="img"
                                :class="item.name">
                                <img v-for="img in item.icon" :src="img" >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview-tab">
                <div 
                    class="preview-tab-item"
                    :class="previewType == 'design' ? 'active' : ''"
                    @click="onPreviewType('design')">零门槛操作</div>
                <div 
                    class="preview-tab-item"
                    :class="previewType == 'tech' ? 'active' : ''"
                    @click="onPreviewType('tech')">核心技术</div>
                <div 
                    class="preview-tab-item"
                    :class="previewType == 'scene' ? 'active' : ''"
                    @click="onPreviewType('scene')">多种场景</div>
            </div>
            <div class="preview-content">
                <img class="preview-line" style="width: 107%; height: 100%; transform: translate3d(0px, 0px, 0px); content-visibility: visible;" src="/static/preview-bg1.svg">
                <div class="preview-des">
                    <div v-show="previewType == 'design'">
                        创意画笔，自由绘制消除形状
                    </div>
                    <div v-show="previewType == 'tech'">
                        双AI引擎，智能检测消除
                    </div>
                    <div v-show="previewType == 'scene'">
                        多种模式，满足多场景消除需求
                    </div>
                </div>
                <div class="preview-img">
                    <img v-show="previewType == 'design'" src="/static/preview1.png">
                    <img v-show="previewType == 'tech'" src="/static/preview2.png">
                    <img v-show="previewType == 'scene'" src="/static/preview3.png">
                </div>
            </div>
        </div>
        <div class="home-footer">
            © OJBK.AI&nbsp;&nbsp;&nbsp;All Rights Reserved.
        </div>
    </div>
</template>

<script>
import Typed from 'typed.js'
import axios from 'axios'

export default {
    data() {
        return {
            codeShow: false,
            menuType: 'all',
            previewType: 'design',
            cateList: [
            {
                    name: 'supSrt',
                    supTitle: '字幕消除',
                    list: [
                        {
                            name: 'textRemove',
                            title: '字幕检测消除 - 快速',
                            tabTitle: '字幕消除 - 快速',
                            des: 'AI检测，消除字幕区域',
                            icon: [
                            require('/static/srt_ai_1.svg'),
                            require('/static/srt_ai_2.svg')
                            ],
                            cateType: 'advance',
                            show: true
                        },
                        {
                            name: 'aiLikeRemove',
                            title: '字幕检测消除 - 高精度',
                            tabTitle: '字幕消除 - 高精度',
                            des: 'AI精准检测，消除字幕区域',
                            icon: [
                            require('/static/srt_frame_1.svg'),
                            require('/static/srt_frame_2.svg'),
                            require('/static/srt_frame_3.svg')
                            ],
                            cateType: 'advance',
                            show: true
                        }
                    ]
                },
                {
                    name: 'supRemove',
                    supTitle: '选区消除',
                    list: [
                        {
                            name: 'areaRemove',
                            title: '框选消除',
                            tabTitle: '矩形消除',
                            des: '消除框选区域',
                            icon: [
                                require('/static/area.svg')
                            ],
                            cateType: 'advance',
                            show: true
                        },
                        {
                            name: 'drawRemove',
                            title: '绘制消除',
                            tabTitle: '绘制消除',
                            des: '消除任意绘制区域',
                            icon: [
                            require('/static/draw.svg')
                            ],
                            cateType: 'advance',
                            show: true
                        }
                    ]
                },
                {
                    
                    name: 'supBang',
                    supTitle: '字幕识别',
                    list: [
                        {
                            name: 'srtText',
                            title: '硬字幕识别文案',
                            tabTitle: '字幕识别为文案',
                            des: '字幕识别为文案',
                            icon: [
                            require('/static/srt_text_2.svg'),
                            require('/static/srt_text_1.svg')
                            ],
                            cateType: 'basic',
                            show: true
                        },
                        {
                            name: 'srtFile',
                            title: '硬字幕识别文件',
                            des: '字幕识别为字幕文件',
                            tabTitle: '字幕识别为文件',
                            icon: [
                            require('/static/srt_file_1.svg'),
                            require('/static/srt_file_2.svg')
                            ],
                            cateType: 'basic',
                            show: true
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        onChangeActiveCateType() {
            document.querySelector('#download').scrollIntoView(true)
        },
        onPreviewType(previewType) {
            this.previewType =  previewType
        },
        onMenuType(typeVal) {
            this.menuType = typeVal

            this.cateList.forEach(item => {
                item.list.forEach(cate => {
                    if (typeVal == 'all') {
                        cate.show = true
                    } else if (typeVal == 'srt') {

                        if (['textRemove', 'aiLikeRemove', 'srtText', 'srtFile'].includes(cate.name)) {
                            cate.show = true
                        } else {
                            cate.show = false
                        }
                    } else if (typeVal == 'remove') {
                        if (['areaRemove', 'drawRemove', 'textRemove', 'aiLikeRemove'].includes(cate.name)) {
                            cate.show = true
                        } else {
                            cate.show = false
                        }
                    }
                })
            })
        },
        onPanLink() {
            window.open('https://pan.baidu.com/s/1BhShtWOo3o10_5u5IbOvUg?pwd=bztd', '_blank')
        },
        onShowCode() {
            this.codeShow = true
        },
        onHideCode() {
            this.codeShow = false
        },
        async getLatest() {
            // let result = await axios.post(`https://inspirecreate.ai/latestVersion`, {
            //     appName: 'inspirecreate.ai'
            // })

            // let versionInfo = result.data.data

            // let exeName = versionInfo.exeName

            let exeName = 'OJBK.zip'
            let url = `https://ojbk-storage.oss-cn-hangzhou.aliyuncs.com/OJBK.zip`
            
            return {
                exeName, 
                url
            }
        },
        async onDownloadVersion() {

            let {
                exeName,
                url
            } = await this.getLatest()

            const link = document.createElement('a')
            link.href = url
            link.download = exeName // 提供下载后的文件名
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
        }
    },
    mounted() {
        
        
    }
}
</script>

<style lang="scss">
.home-app {
    position: relative;
    height: 100%;
    background: #fff;
    font-family: PingFang SC, sans-serif;
    user-select: none;
    min-width: 580px;
    background-repeat: repeat !important;
    overflow-y: auto;

    .home-footer {
        margin-top: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f4f6fa;
        height: 64px;
    }

    .home-content {
        display: flex;
        width: 100%;
        flex-direction: column;
        z-index: 99;
        overflow-x: hidden;

        .preview-content {
            position: relative;
            background: url('/static/preview-bg.webp') 50% / 100% 100% no-repeat;

            .preview-des {
                margin-top: 48px;
                font-size: 40px;
                font-weight: 700;
                text-align: center;
            }

            .preview-img {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 1;

                img {
                    width: 60%;
                }
            }

            .preview-line {
                position: absolute;
            }
        }

        .preview-tab {
            display: flex;
            margin-top: 64px;
            padding: 0 32px;

            &-item {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                flex: 1;
                height: 80px;
                font-size: 24px;
                color: #555;
                cursor: pointer;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    height: 4px;
                    background: rgba(0, 0, 0, 0.05);
                }

                &.active {

                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        bottom: 0;
                        height: 4px;
                        background: #00c1cd;
                    }
                }
            }

        }
        
        .download-btn-wrapper {
            margin-top: 40px;

            .download {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .pan-link {
                    font-size: 14px;
                    color: #f79800;
                    margin-top: 12px;
                    cursor: pointer;
                    text-decoration: underline;
                }

                &-des {
                    margin-top: 12px;
                    font-size: 12px;
                    color: #fff;
                }

                &-btn {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 46px;
                    padding: 0 24px;
                    border-radius: 27px;
                    background: linear-gradient(138.69deg, #b14bf4 -2.74%, #00c1cd 49.11%, #00c1cd 98.64%);
                    font-size: 16px;
                    cursor: pointer;
                    overflow: hidden;
                    user-select: none;
                    color: #fff;

                    @keyframes light-line {
                        0% {
                            transform: translateX(-100%) rotate(-45deg);
                        }
                        100% {
                            transform: translateX(100%) rotate(-45deg);
                        }
                    }

                    &::before {
                        position: absolute;
                        content: "";
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 8px;
                        background: linear-gradient(0deg, #fff, #fceef0 40.1%, #fceef0 55.21%, #f0f3fe);
                        opacity: 0.5;
                        animation: light-line 2s linear infinite;
                    }

                    span {
                        margin-top: -2px;
                    }

                    img {
                        margin-right: 8px;
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }

        .cate-list-wrapper {
            background: url('/static/cate-bg.webp') 50% / 100% 100% no-repeat;
        }

        .cate-items {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 40px;
            padding: 0 32px;

            .cate-item {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 12px;
                padding: 28px 24px 28px 18px;
                width: calc((100% - 40px) / 2);
                cursor: pointer;
                transition: transform 0.1s ease;
                overflow: hidden;
                color: #333;
                white-space: nowrap;

                &.areaRemove {
                    border: 1px solid rgba(78, 226, 229, 1);
                    background: rgba(78, 226, 229, 0.3);
                }

                &.drawRemove {
                    border: 1px solid rgba(247, 152, 0, 1);
                    background: rgba(247, 152, 0, 0.3);
                }

                &.textRemove {
                    border: 1px solid rgba(52, 125, 232, 1);
                    background: rgba(52, 125, 232, 0.3);
                }

                &.aiLikeRemove {
                    border: 1px solid rgba(100, 45, 214, 1);
                    background: rgba(100, 45, 214, 0.3);
                }

                &.srtText {
                    border: 1px solid rgba(250, 203, 66, 1);
                    background: rgba(250, 203, 66, 0.3);
                }

                &.srtFile {
                    border: 1px solid rgba(74, 239, 180, 1);
                    background: rgba(74, 239, 180, 0.3);
                }

                &:hover {
                    transform: scale(1.05);
                }

                .img {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 12px;
                    border: 1px dashed rgba(0, 0, 0, .2);
                    background: rgba(255, 255, 255, 0.7);
                    width: 80px;
                    height: 80px;
                    padding: 8px;
                    min-width: 80px;
                    transition: transform 0.1s ease;

                    &:hover {
                        transform: scale(1.5);
                    }

                    img {
                        width: 100%;
                    }

                    &.drawRemove {
                        img {
                            width: 90%;
                        }
                    }

                    &.scriptDetail {
                        img:nth-child(1) {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 25%;
                            left: 76%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }

                    &.scriptFase {
                        img:nth-child(1) {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 25%;
                            left: 76%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }

                    &.srtFile {
                        img:nth-child(1) {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 20%;
                            left: 80%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }

                    &.srtText {
                        img:nth-child(1) {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 70%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 20%;
                            left: 80%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }

                    &.aiLikeRemove {
                        img:nth-child(1) {
                            position: absolute;
                            top: 68%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 75%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 25%;
                            left: 32%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }

                        img:nth-child(3) {
                            position: absolute;
                            top: 25%;
                            left: 72%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }

                    &.textRemove {
                        img:nth-child(1) {
                            position: absolute;
                            top: 68%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 75%;
                        }

                        img:nth-child(2) {
                            position: absolute;
                            top: 25%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            width: 24px;
                        }
                    }
                }

                .text {

                    .des {
                        margin-top: 12px;
                        font-size: 14px;
                        color: rgba(0, 0, 0, .7);
                        text-align: left;
                    }

                    .title {
                        color: rgba(0, 0, 0, .7);
                        font-weight: 700;
                        font-size: 20px;
                        text-align: left;
                    }
                }

                &:nth-child(4) {
                    margin-top: 16px;
                    margin-left: 0 !important;
                }

                & + .cate-item {
                    margin-left: 24px;
                }
            }
        }
        
        .menu-tab {
            margin-top: 56px;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 8px;
            background: #ededee;
            width: fit-content;
            height: 60px;
            margin-bottom: 12px;

            .menu-item {
                padding: 8px 32px;
                cursor: pointer;

                &.active {
                    color: rgba(0, 0, 0, 0.7);
                    border-radius: 12.366px;
                    font-weight: 600;
                    background: #fff;
                    box-shadow: 1.55px 1.55px 3.099px 0 hsla(0, 0%, 73%, .25);
                }
            }
        }

        .banner {
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            position: relative;
            height: 288px;
            width: 100%;
            background: url('/static/banner-bg.png') 50% / 100% 100% no-repeat;

            &-text {
                display: flex;
                justify-content: center;
                position: relative;
                font-size: 40px;
                z-index: 1;
                white-space: nowrap;
            }

            &-tips {
                margin-top: 24px;
                display: flex;
                justify-content: center;
                position: relative;
                z-index: 1;
                font-size: 20px;
                color: rgba(0, 0, 0, 0.7);
            }

            &::before {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 380px;
                height: 216px;
                background: url('/static/banner-bg-left.png') 50% / 100% 100% no-repeat;
            }

            &::after {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                width: 229px;
                height: 288px;
                background: url('/static/banner-bg-right.png') 50% / 100% 100% no-repeat;
            }
        }
    }

    .home-nav {
        width: 100%;
        height: 80px;
        z-index: 199;
        padding: 0 24px;
        background: url('/static/preview-bg.webp') 500px 100% no-repeat;


        &-content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            margin: 0 auto;
            height: 100%;
            z-index: 199;

            .contact-tips {
                position: relative;
                left: 118px;
                display: flex;
                align-items: center;
                background: linear-gradient(90deg, #fa48a7, #a263ff);
                border-radius: 4px;
                color: #fff;
                font-size: 14px;
                font-weight: 500;
                height: 23px;
                padding: 0 12px;
                transform: translate(-100%);
                width: -moz-max-content;
                width: max-content;
                white-space: nowrap;

                &::before {
                    border-bottom: 12px solid transparent;
                    border-left: 18px solid #a263ff;
                    border-top: 12px solid transparent;
                    content: "";
                    height: 0;
                    position: absolute;
                    right: -15px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 0;
                }
            }

            .contact-dialog {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 88px;
                right: 0;
                background: rgba(255, 255, 255, 1);
                width: 200px;
                border-radius: 12px;
                z-index: 199;
                overflow: hidden;
                height: 204px;
                pointer-events: none;
                backdrop-filter: blur(15px);
                box-shadow: 9px 9px 18px 0 hsla(0, 0%, 73%, .25);


                img {
                    width: 100%;
                }
            }

            .nav-logo {
                display: flex;
                align-items: center;
                color: #00c1cd;
                font-weight: 700;
                font-size: 20px;
                white-space: nowrap;

                .name {
                    margin-left: 4px;
                    background: linear-gradient(138.69deg, #b14bf4 -2.74%, #00c1cd 49.11%, #00c1cd 98.64%);
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                }
            }

            .btns {
                display: flex;
                align-items: center;
            }

            .btn {
                position: relative;
                display: flex;
                align-items: center;
                padding: 8px 24px;
                font-size: 14px;
                box-sizing: border-box;
                background: linear-gradient(270deg, #ffcaa8, #ffddc7);
                border-radius: 27px;
                cursor: pointer;
                overflow: hidden;
                user-select: none;
                height: 42px;

                /* &::before {
                    position: absolute;
                    content: "";
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(0deg, hsla(0, 0%, 100%, 0), hsla(0, 0%, 100%, .15) 40.1%, hsla(0, 0%, 100%, .15) 55.21%, hsla(0, 0%, 100%, 0));
                    animation: light-line 1.6s linear infinite;
                }

                @keyframes light-line {
                    0% {
                        transform: translateX(-100%) rotate(-45deg);
                    }
                    100% {
                        transform: translateX(100%) rotate(-45deg);
                    }
                } */
            }
        }

        img {
            width: 36px;
        }
    }

    .banner-bg {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 0;
        opacity: 0.3;

        img {
            margin-top: 48px;
            max-width: 70%;
            max-height: 80%;
        }
    }

    .home-mask {
        position: relative;
        height: 100%;
        background: linear-gradient(180deg,rgba(35,35,35,0.4) 32%,rgba(35,35,35,.75) 62.6%,#232323);
        z-index: 1;
    }
    
}
</style>

<style lang="scss">
    html, body, div, h1, h2, h3, h4, h5 {
        margin: 0;
        box-sizing: border-box;
    }
    
    html, body {
        width: 100%;
        height: 100%;
        font-family: "Google Sans",arial,sans-serif;
        overflow: hidden;
    }
    
    :root {
        --animate-duration: 1000ms;
        --animate-delay: 0.9s;
    }
</style>